import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Link from "../components/shared/link"
import AddToCalendar from "@culturehq/add-to-calendar"
import "../components/fragments"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Widgets from "../components/widgets"
import ShareLinks from "../components/shareLinks"
import MoreEvents from "../components/moreEvents"
import Organization from "../components/organization"
import Tags from "../components/tags"
import Person from "../components/person"
import ReactPlayer from "react-player"
import { Helmet } from "react-helmet"
import {
  capitalizeFirstLetter,
  renderFormattedText,
  contentExists,
  fixTimezone,
  formatCost,
  formatNiceDateRange,
  formatNiceTimeRange,
  nl2br,
  ParseText,
} from "../utils/ug-utils"
import Breadcrumbs from "../components/breadcrumbs"
import "../styles/event.scss"
import classNames from "classnames"
import Hero from "../components/hero"
import HeroSpotlight from "../components/heroSpotlight"

const EventPage = ({ data, pageContext }) => {
  const pageData = data.events.edges[0].node
  const title = pageData.title
  const subtitle = pageData.field_subtitle
  const start_date = pageData.field_date.value
  const end_date = pageData.field_date.end_value
  // const event_location = nl2br(pageData.field_event_location)
  const event_location = pageData.field_location?.processed ?? ""
  const body = pageData.body !== null ? pageData.body.processed : ``
  const dateDetails = pageData.field_date_details !== null ? pageData.field_date_details.processed : ``
  const locationDetails = pageData.field_location_details !== null ? pageData.field_location_details.processed : ``
  const costDetails = pageData.field_cost_details !== null ? pageData.field_cost_details.processed : ``
  const cost = pageData.field_cost
  const webinarCategory = capitalizeFirstLetter(pageData.field_webinar_category)
  const highlight = pageData.field_event_highlight
  const imageData = data.images.edges
  const heroImage = pageData.relationships.field_hero_image
  const links = pageData.field_links
  const organizations = pageData.relationships?.field_organizations
  const people = pageData.relationships?.field_people
  const video = pageData.relationships?.field_event_video
  const videoRemoteURL = video?.field_media_oembed_video
  const videoLocalURL = video?.relationships?.field_media_video_file?.localFile.publicURL
  const tags = pageData.relationships?.field_tags
  const webinar = pageData.field_event_type === "webinar" ? true : false
  const duration = pageData.field_webinar_video_duration
  const videoURL = videoRemoteURL ? videoRemoteURL : videoLocalURL

  // WidgetData contains all widgets (paragraphs) that are available - when adding a new widget, validate that the correct items are selected
  // using a comparison to __typename.  This will be paragraph__WIDGETNAME - you can pass the widgetsData variable through to your component.

  const widgetsData = contentExists(pageData.relationships.field_widgets) ? pageData.relationships.field_widgets : null
  const footerWidgetsData = contentExists(pageData.relationships.field_footer_widgets)
    ? pageData.relationships.field_footer_widgets
    : null
  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: webinar ? "webinar-page" : "event-page",
          "aria-label": "Webinar",
        }}
      />
      <Seo title={title} keywords={[`gatsby`, `application`, `react`]} />

      {/**** Header and Title ****/}
      {contentExists(heroImage) &&
        <div id="rotator" className={`short-hero image`}>
          <Hero imgData={[{ node: heroImage }]} />
          <div>
            <HeroSpotlight heroStyle="short-hero">
              <h1 className="fancy-title">{title}</h1>
            </HeroSpotlight>
          </div>
        </div>
      }

      {/**** Body content ****/}
      <div className="page-container">
        <Container>
          <Breadcrumbs targetUrl="/events" activeTitle={title} />
          {!contentExists(heroImage) && <h1 className="fancy-title">{title}</h1>}
          {/**** Widgets content ****/}
          <Container className="row row-with-vspace site-content event event-full" as="article">
            {/**** Widgets content ****/}
            <Row className="row-with-vspace">
              <Col lg={4} className="sidebar sidebar-left">
                {links?.length > 0 && (
                  <div class="event-links">
                    {links?.map(({ title, uri }, index) => (
                      <div className="bullet-link">
                        <i className="fa-solid fa-play" aria-hidden="true"></i>
                        <Link className="link-item" to={uri}>
                          {title}
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
                <dl class="row event-detail">
                  {!webinar && (
                    <>
                      <dt class="col-2">
                        <i className="fa fa-calendar-o" aria-hidden="true"></i>
                      </dt>
                      <dd class="col-10">
                        <div>
                          {formatNiceDateRange(start_date, end_date)}
                          <br />
                          {formatNiceTimeRange(start_date, end_date)}
                        </div>
                        {dateDetails && (
                          <div className="event-detail-detail" dangerouslySetInnerHTML={{ __html: dateDetails }} />
                        )}
                        <div className="add-to-calendar-wrapper">
                          <AddToCalendar
                            event={{
                              name: title,
                              details: renderFormattedText(pageData.body, true),
                              location: event_location,
                              startsAt: start_date,
                              endsAt: end_date,
                            }}
                            children={
                              <span className="add-to-calendar-label">
                                <i className="fa-solid fa-plus" aria-hidden="true"></i>Add to My Calendar
                              </span>
                            }
                          />
                        </div>
                      </dd>
                    </>
                  )}

                  {webinarCategory && (
                    <>
                      <dt class="col-2">
                        <i className="fa fa-messages" aria-hidden="true"></i>
                      </dt>
                      <dd class="col-10">
                        <div>{webinarCategory}</div>
                      </dd>
                    </>
                  )}

                  {event_location && (
                    <>
                      <dt class="col-2">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                      </dt>
                      <dd class="col-10">
                        <div dangerouslySetInnerHTML={{ __html: event_location }} />
                        {locationDetails && (
                          <div className="event-detail-detail" dangerouslySetInnerHTML={{ __html: locationDetails }} />
                        )}
                      </dd>
                    </>
                  )}

                  {duration !== undefined && duration !== null && (
                    <>
                      <dt class="col-2">
                        <i className="fa-solid fa-clock" aria-hidden="true"></i>
                      </dt>
                      <dd class="col-10">
                        <div>{`${duration} min`}</div>
                      </dd>
                    </>
                  )}

                  {cost !== undefined && cost !== null && (
                    <>
                      <dt class="col-2">
                        <i className="fa-solid fa-tag fa-rotate-90" aria-hidden="true"></i>
                      </dt>
                      <dd class="col-10 event-detail-cost">
                        <div>{cost < 0 ? `TBD` : cost === 0 ? `Free` : `$${formatCost(cost)}`}</div>
                        {costDetails && (
                          <div className="event-detail-detail" dangerouslySetInnerHTML={{ __html: costDetails }} />
                        )}
                      </dd>
                    </>
                  )}

                  <dt class="col-2">
                    <i className="fa-solid fa-share-nodes" aria-hidden="true"></i>
                  </dt>
                  <dd class="col-10 event-detail-share">
                    <ShareLinks bgStyle={{ fill: "var(--uog-blue-muted)" }} iconFillColor="black" />
                  </dd>
                </dl>

                {people?.length > 0 && (
                  <div className="event-people">
                    <h4>Your Host{people?.length === 1 ? "" : "s"}:</h4>
                    {people?.map((person) => (
                      <Person personData={person} />
                    ))}
                  </div>
                )}

                {tags?.length > 0 && (
                  <div className="tags-container d-none d-sm-none d-lg-block">
                    <h4>Tags</h4>
                    <Tags withId={false} tagData={tags} className={"tags"} urlPrefix="/news/tags" />
                  </div>
                )}
              </Col>

              <Col lg={8} className="content-area">
                {contentExists(videoURL) && !webinar ? (
                  <div className="player-wrapper">
                    <ReactPlayer
                      id={video.drupal_id}
                      className="react-player"
                      controls={true}
                      url={videoURL}
                      width="100%"
                      height="100%"
                    />
                  </div>
                ) : null}

                <div className="subtitle">{subtitle}</div>
                {/* <div className="event-body" dangerouslySetInnerHTML={{ __html: body }} /> */}
                <div className="event-body">
                  <ParseText textContent={body} />
                </div>
                <Widgets pageData={widgetsData} />

                {/* {links?.length > 0 && (
                  <div class="event-links">
                    {links?.map(({ title, uri }, index) => (
                      <div className="bullet-link">
                        <i className="fa-solid fa-play" aria-hidden="true"></i>
                        <Link className="link-item" to={uri}>
                          {title}
                        </Link>
                      </div>
                    ))}
                  </div>
                )} */}

                {tags?.length > 0 && (
                  <div className="tags-container d-block d-sm-block d-lg-none ">
                    <h4>Tags</h4>
                    <Tags withId={false} tagData={tags} className={"tags"} urlPrefix="/news/tags" />
                  </div>
                )}
              </Col>
            </Row>
            <Row as="footer">
              {contentExists(videoURL) && webinar ? (
                <div className="player-wrapper">
                  <ReactPlayer
                    id={video.drupal_id}
                    className="react-player"
                    controls={true}
                    url={videoURL}
                    width="100%"
                    height="100%"
                  />
                </div>
              ) : null}
              <Widgets pageData={footerWidgetsData} />

              {organizations?.length > 0 && (
                <div className="event-organizations">
                  <h3>Presented by</h3>
                  <div
                    className={classNames("d-flex", {
                      "justify-content-evenly": organizations.length === 2,
                      "justify-content-around": organizations.length > 2,
                    })}
                  >
                    {organizations?.map((organization) => (
                      <Organization organizationData={organization} mini />
                    ))}
                  </div>
                </div>
              )}
            </Row>
          </Container>
          {/* <Row className="row-with-vspace">
            <Col xs={12}>
              <div className="share-content-bottom">
                <ShareLinks title="Share with friends" />
              </div>
              <h2 className="header-title">
                <span>
                  Other events and learning opportunities you may like
                </span>
              </h2>
              {webinar ? (
                <MoreEvents moreEventsData={data.moreWebinars} />
              ) : (
                <MoreEvents moreEventsData={data.moreEvents} />
              )}
            </Col>
          </Row> */}
        </Container>
      </div>
    </Layout>
  )
}

export default EventPage

export const query = graphql`
  fragment Event on node__event {
    ...Node
    drupal_internal__nid
    path {
      alias
    }
    field_subtitle
    field_event_type
    field_event_highlight
    field_webinar_video_duration
    field_date {
      value
      end_value
    }
    field_location {
      processed
      summary
    }
    field_cost
    field_date_details {
      processed
    }
    field_location_details {
      processed
    }
    field_cost_details {
      processed
    }
    body {
      processed
      summary
    }
    field_links {
      title
      full_url
      uri
    }
    field_domain_all_affiliates
    created(formatString: "YYYY-MM-DD")
    relationships {
      field_organizations {
        ...ParagraphOrganization
      }
      field_people {
        ...ParagraphPerson
      }
      field_widgets {
        __typename
        ...ParagraphSectionTabs
        ...ParagraphCardCollection
        ...ParagraphCallToAction
        ...ParagraphChartWidget
        ...ParagraphGeneralText
        ...ParagraphLeadParagraph
        ...ParagraphLinksWidget
        ...ParagraphSection
        ...ParagraphMediaText
        ...ParagraphStatsWidget
        ...ParagraphShareLinks
        ...ParagraphPlaybackButtonWidget
      }
      field_footer_widgets {
        __typename
        ...ParagraphGeneralText
        ...ParagraphLeadParagraph
        ...ParagraphLinksWidget
        ...ParagraphMediaText
        ...ParagraphSection
        ...ParagraphStatsWidget
      }
      field_hero_image {
        ...HeroImage
      }
      _card_image: field_card_image {
        ...CardImageHorizontal
      }
      _card_hero_image: field_hero_image {
        ...CardImageHorizontal
      }
      field_event_video {
        __typename
        ...MediaVideo
        ...MediaRemoteVideo
      }
      field_webinar_category {
        ...TaxonomyTerm
      }
    }
  }
  fragment Webinar on node__event {
    ...Event
    relationships {
      field_webinar_category {
        ...TaxonomyTerm
      }
    }
  }
  query ($id: String) {
    events: allNodeEvent(filter: { id: { eq: $id } }) {
      edges {
        node {
          ...Event
        }
      }
    }
    images: allMediaImage(filter: { relationships: { node__event: { elemMatch: { id: { eq: $id } } } } }) {
      edges {
        node {
          ...MediaImage
          relationships {
            field_media_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(transformOptions: { cropFocus: CENTER }, placeholder: BLURRED, aspectRatio: 3)
                }
              }
            }
          }
        }
      }
    }
    moreEvents: allNodeEvent(
      limit: 10
      filter: { id: { ne: $id } }
      sort: { fields: [sticky, field_date___value], order: [DESC, ASC] }
    ) {
      edges {
        node {
          ...Event
          field_webinar_video_duration
        }
      }
    }
    moreWebinars: allNodeEvent(
      limit: 10
      filter: { id: { ne: $id }, field_event_type: { eq: "webinar" } }
      sort: { fields: [sticky, field_date___value], order: [DESC, ASC] }
    ) {
      edges {
        node {
          ...Webinar
          field_webinar_video_duration
        }
      }
    }
  }
`
