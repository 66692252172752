import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { Row, Col } from "react-bootstrap"

import { contentExists } from "../utils/ug-utils"

const Person = ({ personData }) => {
  const photo = personData.relationships?.field_person_photo
  const imageSource = photo?.relationships?.field_media_image.localFile
  const altText = photo?.field_media_image?.alt

  return (
    <div className="person" key={personData.id}>
      {contentExists(imageSource) && (
        <div className="person-photo">
          <GatsbyImage image={imageSource.childImageSharp.gatsbyImageData} alt={altText} />
        </div>
      )}
      <h5 className="person-name">{personData.field_person_name}</h5>
      <h6 className="person-role">{personData.field_person_role}</h6>
      <div className="person-bio" dangerouslySetInnerHTML={{ __html: personData.field_person_bio?.processed }} />
    </div>
  )
}

Person.propTypes = {
  personData: PropTypes.object.isRequired,
}

export default Person

export const query = graphql`
  fragment ParagraphPerson on paragraph__person {
    id
    field_person_bio {
      processed
    }
    field_person_name
    field_person_role
    relationships {
      field_person_photo {
        ...Image
        # field_media_image {
        #   alt
        # }
        # relationships {
        #   field_media_image {
        #     localFile {
        #       childImageSharp {
        #         gatsbyImageData(
        #           width: 300
        #           aspectRatio: 0.75
        #           transformOptions: { cropFocus: CENTER }
        #           placeholder: BLURRED
        #         )
        #       }
        #     }
        #   }
        # }
      }
    }
  }
`
