import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { Row, Col } from "react-bootstrap"

import { contentExists } from "../utils/ug-utils"

const Organization = ({ organizationData, mini }) => {
  const logo = organizationData.relationships?.field_organization_logo
  const imageSource = logo?.relationships?.field_media_image.localFile
  const altText = logo?.field_media_image?.alt

  if (mini) {
    return (
      <div className="organization-logo" key={organizationData.id}>
      {contentExists(imageSource) && (
        <GatsbyImage image={imageSource.childImageSharp.gatsbyImageData} alt={altText} />
      )}
      </div>
    )
  }

  return (
    <Row className="organization" key={organizationData.id}>
      <Col md={4}>
        {contentExists(imageSource) && (
          <div className="organization-logo">
            <GatsbyImage image={imageSource.childImageSharp.gatsbyImageData} alt={altText} />
          </div>
        )}
      </Col>
      <Col md={8}>
        <h3 className="organization-name underline-secondary">{organizationData.field_organization_name}</h3>
        <div className="organization-role">{organizationData.field_organization_role}</div>
        <div
          className="organization-bio"
          dangerouslySetInnerHTML={{ __html: organizationData.field_organization_bio?.processed }}
        />
      </Col>
    </Row>
  )
}

Organization.propTypes = {
  organizationData: PropTypes.object.isRequired,
}

export default Organization

export const query = graphql`
  fragment ParagraphOrganization on paragraph__organization {
    id
    field_organization_bio {
      processed
    }
    field_organization_name
    relationships {
      field_organization_logo {
        # ...Image
        field_media_image {
          alt
        }
        relationships {
          field_media_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 250
                  height: 250
                  transformOptions: { fit: CONTAIN }
                  backgroundColor: "transparent"
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`
