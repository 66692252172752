import React from "react"
import PropTypes from "prop-types"
import CardCollectionNode from "../components/cardCollectionNode"

const MoreEvents = ({ moreEventsData, type }) => {
  return (
    <CardCollectionNode nodes={moreEventsData.edges} cardStyle="aad-event-teaser" displayMode="slider" backgroundColor="" ariaLabel="More events"/>
  )
}

MoreEvents.propTypes = {
  moreEventsData: PropTypes.object.isRequired,
}

export default MoreEvents
